import React, { useState } from "react";
import { graphql, Link } from "gatsby";
import styled from "styled-components";
import Div100vh from "react-div-100vh";
import Helmet from "react-helmet";

// Components
import { Gallery } from "../components/images/gallery";
import { Thumbnails } from "../components/project/thumbnails";
import { About } from "../components/project/about";

// Previews
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";

const Page = styled.div`
  height: 100%;
`;

const TopCaption = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;

  padding: 18px 20px 22px 20px;

  & a {
    color: rgba(170, 170, 170, 1);
    margin: 0 0 0 auto;

    &:hover {
      color: #353535;
    }

    @media (max-width: 767px) {
      margin: 0;
    }
  }

  @media (max-width: 767px) {
    padding: 10px 20px 22px 20px;
  }
`;

const Caption = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;

  z-index: 100;

  overflow: scroll;

  height: ${props => props.height};
  min-height: ${props => props.minHeight};

  transform: translateY(${props => props.translateY});
  transition: 500ms transform ease;

  background: #fff;

  & .project-title {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    & h1,
    & p {
      margin: 0;
    }
  }

  & .top-line-information {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;

    padding: 26px 20px 0 20px;

    & button {
      &:last-of-type {
        margin: 0 0 0 20px;
      }

      &:hover {
        color: rgba(170, 170, 170, 1);
      }
    }

    & .current-image-title {
      padding: 0 0 0 20px;
    }
  }

  & .content-container {
    padding: 12px 20px 20px 20px;
  }

  @media (max-width: 767px) {
    & .project-title {
      display: none;
    }

    & .buttons {
      width: 100%;

      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
    }
  }
`;

const Counter = styled.div`
  display: none;

  @media (max-width: 767px) {
    display: block;
  }
`;

const InterviewText = styled.div`
  & .name {
    margin-bottom: 0;
    padding: 0 0 0 25px;
  }

  & .body-text {
    & p:first-of-type {
      margin-top: 0;
    }
  }
`;

const InterviewTextBottom = styled.div`
  & .name {
    margin-top: 0;
    padding: 0 0 0 25px;
  }

  & .body-text {
    & p:last-of-type {
      margin-bottom: 0;
    }
  }
`;

const QuoteText = styled.div`
  color: #353535;
  padding: 0 0 0 25px;

  & p:last-of-type {
    margin: 0;
  }
`;

const FootnoteText = styled.div`
  margin: 1em 0;
  color: #aaaaaa;

  & p {
    margin: 0;

    font-size: 11px;
    line-height: 18px;
  }
`;

const PhotographyProject = ({ data }) => {
  const [isAboutVisible, setIsAboutVisible] = useState(false);
  const [isThumbnailsVisible, setIsThumbnailsVisible] = useState(false);

  const [currentSlide, setCurrentSlide] = useState(0);
  const [totalSlides, setTotalSlides] = useState(0);

  const closeDrawer = () => {
    setIsAboutVisible(false);
    setIsThumbnailsVisible(false);
  };

  const galleryImages = data.prismicPhotographyProject.data.gallery.filter(
    content => content.image.fluid !== null
  );

  const text = data.prismicPhotographyProject.data.body.map(
    (content, index) => {
      if (content.slice_type === "text") {
        return (
          <div key={`body_text_${index}_${content.id}`}>
            <div
              className="body-text"
              dangerouslySetInnerHTML={{
                __html: content.primary.text.html,
              }}
            />
          </div>
        );
      }

      if (content.slice_type === "interview_text") {
        return (
          <InterviewText key={`interview_text_${index}_${content.id}`}>
            <p className="name">{content.primary.name}</p>
            <div
              className="body-text"
              dangerouslySetInnerHTML={{
                __html: content.primary.text.html,
              }}
            />
          </InterviewText>
        );
      }

      if (content.slice_type === "interview_text_bottom") {
        return (
          <InterviewTextBottom
            key={`interview_text_bottom_${index}_${content.id}`}
          >
            <div
              className="body-text"
              dangerouslySetInnerHTML={{
                __html: content.primary.text.html,
              }}
            />
            <p className="name">{content.primary.name}</p>
          </InterviewTextBottom>
        );
      }

      if (content.slice_type === "quote_text") {
        return (
          <QuoteText key={`quote_text_${index}_${content.id}`}>
            <div
              className="body-text"
              dangerouslySetInnerHTML={{
                __html: content.primary.text.html,
              }}
            />
            <p className="name">{content.primary.name}</p>
          </QuoteText>
        );
      }

      if (content.slice_type === "footnotes") {
        return (
          <FootnoteText key={`footnotes_${index}_${content.id}`}>
            <div
              className="body-text"
              dangerouslySetInnerHTML={{
                __html: content.primary.text.html,
              }}
            />
          </FootnoteText>
        );
      }
    }
  );

  return (
    <>
      <Helmet
        title={`${data.prismicPhotographyProject.data.title.text} | Mitra Tabrizian`}
        meta={[
          {
            name: "og:image",
            content: `${data.prismicPhotographyProject.data.thumbnail.url}`,
          },
          {
            name: "og:image:secure_url",
            content: `${data.prismicPhotographyProject.data.thumbnail.url}`,
          },
          {
            name: "og:image:width",
            content: `1200`,
          },
          {
            name: "og:image:height",
            content: `630`,
          },
          {
            name: "og:locale",
            content: `en`,
          },
          {
            name: "twitter:title",
            content: `${data.prismicPhotographyProject.data.title.text} | Mitra Tabrizian`,
          },
          {
            name: "twitter:card",
            content: `summary_large_image`,
          },
          {
            name: "twitter:image",
            content: `${data.prismicPhotographyProject.data.thumbnail.url}`,
          },
        ]}
      />

      <Div100vh>
        <Page>
          <TopCaption>
            <Link to={"/photography"}>Back</Link>
            <Counter>
              <span>
                {currentSlide + 1}/{totalSlides}
              </span>
            </Counter>
          </TopCaption>
          {galleryImages.length >= 1 && (
            <Gallery
              images={galleryImages}
              currentSlide={currentSlide}
              setCurrentSlide={setCurrentSlide}
              setTotalSlides={setTotalSlides}
              totalSlides={totalSlides}
            />
          )}
          <Caption
            height={isAboutVisible || isThumbnailsVisible ? `100%` : `60px`}
            minHeight={isAboutVisible || isThumbnailsVisible ? `100%` : `0`}
            translateY={
              isAboutVisible || isThumbnailsVisible ? `0` : `calc(100% - 59px)`
            }
          >
            <div className="top-line-information">
              <div className="project-title">
                <h1>{data.prismicPhotographyProject.data.title.text}</h1>
                {!isAboutVisible &&
                  !isThumbnailsVisible &&
                  galleryImages.length >= 1 && (
                    <>
                      {galleryImages[currentSlide].image.alt !== null && (
                        <p className="current-image-title">
                          {" "}
                          {galleryImages[currentSlide].image.alt}
                        </p>
                      )}
                    </>
                  )}
              </div>

              <div className="buttons">
                {!isAboutVisible && !isThumbnailsVisible && (
                  <>
                    {text.length >= 1 && (
                      <button onClick={() => setIsAboutVisible(true)}>
                        About
                      </button>
                    )}
                    {data.prismicPhotographyProject.data.gallery.length >=
                      2 && (
                      <button onClick={() => setIsThumbnailsVisible(true)}>
                        Thumbnails
                      </button>
                    )}
                  </>
                )}

                {(isAboutVisible || isThumbnailsVisible) && (
                  <button onClick={() => closeDrawer()}>Close</button>
                )}
              </div>
            </div>

            {isThumbnailsVisible && (
              <Thumbnails
                images={data.prismicPhotographyProject.data.gallery}
                setIsThumbnailsVisible={setIsThumbnailsVisible}
                setCurrentSlide={setCurrentSlide}
                display={isThumbnailsVisible}
              />
            )}
            {isAboutVisible && (
              <About
                text={text}
                setIsAboutVisible={setIsAboutVisible}
                display={isAboutVisible}
              />
            )}
          </Caption>
        </Page>
      </Div100vh>
    </>
  );
};

export default withPrismicPreview(PhotographyProject);

export const query = graphql`
  query Photography($uid: String!) {
    prismicPhotographyProject(uid: { eq: $uid }) {
      url
      _previewable
      data {
        title {
          text
        }
        thumbnail {
          url(imgixParams: { w: 1200, h: 630, crop: "max", auto: "format" })
        }
        body {
          ... on PrismicPhotographyProjectDataBodyText {
            id
            slice_type
            primary {
              text {
                html
              }
            }
          }
          ... on PrismicPhotographyProjectDataBodyInterviewText {
            id
            slice_type
            primary {
              name
              text {
                html
              }
            }
          }
          ... on PrismicPhotographyProjectDataBodyInterviewTextBottom {
            id
            slice_type
            primary {
              name
              text {
                html
              }
            }
          }
          ... on PrismicPhotographyProjectDataBodyQuoteText {
            id
            primary {
              text {
                html
              }
              name
            }
            slice_type
          }
          ... on PrismicPhotographyProjectDataBodyFootnotes {
            id
            primary {
              text {
                html
              }
            }
            slice_type
          }
        }
        gallery {
          image {
            alt
            fluid(imgixParams: { auto: "format" }) {
              srcWebp
              srcSetWebp
            }
            dimensions {
              width
              height
            }
          }
        }
      }
    }
  }
`;
