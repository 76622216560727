import React, { useState } from "react";
import { graphql, Link } from "gatsby";
import styled from "styled-components";
import Helmet from "react-helmet";

// Components
import { FilmThumbnails } from "../components/project/thumbnails";
import { About } from "../components/project/about";

// Previews
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";

const Page = styled.div`
  height: 100%;
  padding: 0 0 60px 0;
`;

const TopCaption = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;

  padding: 18px 20px 22px 20px;

  & a {
    color: rgba(170, 170, 170, 1);
    margin: 0 0 0 auto;

    &:hover {
      color: #353535;
    }

    @media (max-width: 767px) {
      margin: 0;
    }
  }

  @media (max-width: 767px) {
    padding: 10px 20px 22px 20px;
  }
`;

const Caption = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;

  z-index: 100;

  overflow: scroll;

  height: ${props => props.height};
  min-height: ${props => props.minHeight};

  transform: translateY(${props => props.translateY});
  transition: 500ms transform ease;

  background: #fff;

  & .project-title {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    & h1,
    & p {
      margin: 0;
    }
  }

  & .top-line-information {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;

    padding: 26px 20px 0 20px;

    & button {
      &:last-of-type {
        margin: 0 0 0 20px;
      }

      &:hover {
        color: rgba(170, 170, 170, 1);
      }
    }

    & .trailer-link {
      margin: 0 0 0 5px;
    }
  }

  & .content-container {
    padding: 12px 20px 20px 20px;
  }

  @media (max-width: 767px) {
    & .project-title {
      display: none;
    }

    & .buttons {
      width: 100%;

      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
    }
  }
`;

const ThumbnailsContainer = styled.div`
  padding: 0 20px;
`;

const InterviewText = styled.div`
  & .name {
    margin-bottom: 0;
    padding: 0 0 0 25px;
  }

  & .body-text {
    & p:first-of-type {
      margin-top: 0;
    }
  }
`;

const InterviewTextBottom = styled.div`
  & .name {
    margin-top: 0;
    padding: 0 0 0 25px;
  }

  & .body-text {
    & p:last-of-type {
      margin-bottom: 0;
    }
  }
`;

const QuoteText = styled.div`
  color: #353535;
  padding: 0 0 0 25px;

  & p:last-of-type {
    margin: 0;
  }
`;

const FootnoteText = styled.div`
  margin: 1em 0;
  color: #aaaaaa;

  & p {
    margin: 0;

    font-size: 11px;
    line-height: 18px;
  }
`;

const FilmProject = ({ data }) => {
  const [isAboutVisible, setIsAboutVisible] = useState(false);
  const [isThumbnailsVisible, setIsThumbnailsVisible] = useState(false);

  const closeDrawer = () => {
    setIsAboutVisible(false);
    setIsThumbnailsVisible(false);
  };

  const text = data.prismicFilmProject.data.body.map((content, index) => {
    if (content.slice_type === "text") {
      return (
        <div key={`body_text_${index}_${content.id}`}>
          <div
            className="body-text"
            dangerouslySetInnerHTML={{
              __html: content.primary.text.html,
            }}
          />
        </div>
      );
    }

    if (content.slice_type === "interview_text") {
      return (
        <InterviewText key={`interview_text_${index}_${content.id}`}>
          <p className="name">{content.primary.name}</p>
          <div
            className="body-text"
            dangerouslySetInnerHTML={{
              __html: content.primary.text.html,
            }}
          />
        </InterviewText>
      );
    }

    if (content.slice_type === "interview_text_bottom") {
      return (
        <InterviewTextBottom
          key={`interview_text_bottom_${index}_${content.id}`}
        >
          <div
            className="body-text"
            dangerouslySetInnerHTML={{
              __html: content.primary.text.html,
            }}
          />
          <p className="name">{content.primary.name}</p>
        </InterviewTextBottom>
      );
    }

    if (content.slice_type === "quote_text") {
      return (
        <QuoteText key={`quote_text_${index}_${content.id}`}>
          <div
            className="body-text"
            dangerouslySetInnerHTML={{
              __html: content.primary.text.html,
            }}
          />
          <p className="name">{content.primary.name}</p>
        </QuoteText>
      );
    }

    if (content.slice_type === "footnotes") {
      return (
        <FootnoteText key={`footnotes_${index}_${content.id}`}>
          <div
            className="body-text"
            dangerouslySetInnerHTML={{
              __html: content.primary.text.html,
            }}
          />
        </FootnoteText>
      );
    }
  });

  return (
    <>
      <Helmet
        title={`${data.prismicFilmProject.data.title.text} | Mitra Tabrizian`}
        meta={[
          {
            name: "og:image",
            content: `${data.prismicFilmProject.data.thumbnail.url}`,
          },
          {
            name: "og:image:secure_url",
            content: `${data.prismicFilmProject.data.thumbnail.url}`,
          },
          {
            name: "og:image:width",
            content: `1200`,
          },
          {
            name: "og:image:height",
            content: `630`,
          },
          {
            name: "og:locale",
            content: `en`,
          },
          {
            name: "twitter:title",
            content: `${data.prismicFilmProject.data.title.text} | Mitra Tabrizian`,
          },
          {
            name: "twitter:card",
            content: `summary_large_image`,
          },
          {
            name: "twitter:image",
            content: `${data.prismicFilmProject.data.thumbnail.url}`,
          },
        ]}
      />

      <Page>
        <TopCaption>
          <Link to={"/film"}>Back</Link>
        </TopCaption>

        <ThumbnailsContainer>
          <FilmThumbnails
            images={data.prismicFilmProject.data.gallery}
            display={isThumbnailsVisible}
            trailer={data.prismicFilmProject.data.trailer}
          />
        </ThumbnailsContainer>

        <Caption
          height={isAboutVisible ? `100%` : `60px`}
          minHeight={isAboutVisible ? `100%` : `0`}
          translateY={isAboutVisible ? `0` : `calc(100% - 59px)`}
        >
          <div className="top-line-information">
            <div className="project-title">
              <h1>{data.prismicFilmProject.data.title.text}</h1>
            </div>

            <div className="buttons">
              {!isAboutVisible && (
                <>
                  {text.length >= 1 && (
                    <button onClick={() => setIsAboutVisible(true)}>
                      About
                    </button>
                  )}
                </>
              )}

              {isAboutVisible && (
                <button onClick={() => closeDrawer()}>Close</button>
              )}
            </div>
          </div>

          {isAboutVisible && (
            <About
              text={text}
              setIsAboutVisible={setIsAboutVisible}
              display={isAboutVisible}
            />
          )}
        </Caption>
      </Page>
    </>
  );
};

export default withPrismicPreview(FilmProject);

export const query = graphql`
  query Film($uid: String!) {
    prismicFilmProject(uid: { eq: $uid }) {
      url
      _previewable
      data {
        title {
          html
          text
        }
        thumbnail {
          url(imgixParams: { w: 1200, h: 630, crop: "max", auto: "format" })
        }
        trailer {
          html
        }
        body {
          ... on PrismicFilmProjectDataBodyText {
            id
            slice_type
            primary {
              text {
                html
              }
            }
          }
          ... on PrismicFilmProjectDataBodyInterviewText {
            id
            slice_type
            primary {
              name
              text {
                html
              }
            }
          }
          ... on PrismicFilmProjectDataBodyInterviewTextBottom {
            id
            slice_type
            primary {
              name
              text {
                html
              }
            }
          }
          ... on PrismicFilmProjectDataBodyQuoteText {
            id
            primary {
              text {
                html
              }
              name
            }
            slice_type
          }
          ... on PrismicFilmProjectDataBodyFootnotes {
            id
            primary {
              text {
                html
              }
            }
            slice_type
          }
        }
        gallery {
          image {
            alt
            fluid(imgixParams: { auto: "format" }) {
              srcWebp
              srcSetWebp
            }
            dimensions {
              width
              height
            }
          }
        }
      }
    }
  }
`;
