import React from "react";
import { graphql } from "gatsby";
import { createBreakpoint } from "react-use";
import Helmet from "react-helmet";

// Layout
import { Page, Grid } from "../components/layout/layout-components";

// Components
import { ProjectPhotographyDesktop } from "../components/projects/project-desktop";
import { ProjectPhotographyMobile } from "../components/projects/project-mobile";

// Previews
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";

const useBreakpoint = createBreakpoint({ L: 768, S: 767 });

const Photography = ({ data }) => {
  const breakpoint = useBreakpoint();

  return (
    <>
      <Helmet title={`Photography | Mitra Tabrizian`} />
      <Page>
        <Grid>
          {breakpoint === "L" ? (
            <ProjectPhotographyDesktop data={data} />
          ) : (
            <ProjectPhotographyMobile data={data} />
          )}
        </Grid>
      </Page>
    </>
  );
};

export default withPrismicPreview(Photography);

export const query = graphql`
  {
    prismicPhotography {
      url
      _previewable
      data {
        projects {
          project {
            document {
              ... on PrismicPhotographyProject {
                id
                url
                data {
                  title {
                    text
                  }
                  thumbnail {
                    alt
                    fluid(imgixParams: { auto: "format" }) {
                      srcWebp
                      srcSetWebp
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    prismicArchive {
      id
      data {
        thumbnail {
          alt
          fluid(imgixParams: { auto: "format" }) {
            srcWebp
            srcSetWebp
          }
        }
      }
    }
  }
`;
