import React from "react";
import { Link, graphql } from "gatsby";
import styled from "styled-components";
import Div100vh from "react-div-100vh";

// Previews
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";

const Page = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  height: 100%;
  padding: 66px 0;
`;

const Index = ({ data }) => (
  <Div100vh>
    <Page>
      <Link to={"/photography"}>
        {data.prismicHomepage.data.image.fluid !== null && (
          <img
            srcSet={data.prismicHomepage.data.image.fluid.srcSetWebp}
            src={data.prismicHomepage.data.image.fluid.srcWebp}
            alt={data.prismicHomepage.data.image.alt}
            loading="lazy"
          />
        )}
      </Link>
    </Page>
  </Div100vh>
);

export default withPrismicPreview(Index);

export const query = graphql`
  {
    prismicHomepage {
      url
      _previewable
      data {
        image {
          alt
          fluid(
            imgixParams: { w: 3500, auto: "format" }
            srcSetBreakpoints: [200, 400, 800, 1200, 1600, 3500]
          ) {
            srcWebp
            srcSetWebp
          }
        }
      }
    }
  }
`;
